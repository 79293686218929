import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/DefaultLayout.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <blockquote>
      <p parentName="blockquote">{`„Die Gebetshaltung ist der beste Zustand; denn in diesem Zustand hält der Mensch Zwiesprache mit Gott. `}{`[...]`}{` Wahrlich, das Gebet verleiht Leben.”`}</p>
      <cite>—‘Abdu’l-Bahá</cite>
    </blockquote>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/ab55e55da99b6a964f8e8081989b7cdd/1f748/andachten.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": ["noopener"]
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAABAUA/8QAFgEBAQEAAAAAAAAAAAAAAAAAAwAB/9oADAMBAAIQAxAAAAEL4aiStpuy/8QAGhAAAQUBAAAAAAAAAAAAAAAAAQACAxIUMf/aAAgBAQABBQLRIRpZfY1dUQqj3//EABYRAQEBAAAAAAAAAAAAAAAAAAABEf/aAAgBAwEBPwGVj//EABYRAQEBAAAAAAAAAAAAAAAAAAEAEf/aAAgBAgEBPwFC2//EABsQAAEEAwAAAAAAAAAAAAAAABEAARBBMlFx/9oACAEBAAY/AgVbi9rB47H/xAAcEAEAAgIDAQAAAAAAAAAAAAABABEhMUFRccH/2gAIAQEAAT8hWsFlCFJHQwj6S5bbrybUYlx5i5H/2gAMAwEAAgADAAAAEMvf/8QAFhEBAQEAAAAAAAAAAAAAAAAAAQAR/9oACAEDAQE/EEWxf//EABYRAQEBAAAAAAAAAAAAAAAAAAABIf/aAAgBAgEBPxCSNP/EABwQAQEAAgMBAQAAAAAAAAAAAAERACExQWFRcf/aAAgBAQABPxA8jniN5E3U1jXehJLAcPuDgq7kPF9wOuzrDmUCPU9zUW/pn//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "andachten",
            "title": "andachten",
            "src": "/static/ab55e55da99b6a964f8e8081989b7cdd/e5166/andachten.jpg",
            "srcSet": ["/static/ab55e55da99b6a964f8e8081989b7cdd/f93b5/andachten.jpg 300w", "/static/ab55e55da99b6a964f8e8081989b7cdd/b4294/andachten.jpg 600w", "/static/ab55e55da99b6a964f8e8081989b7cdd/e5166/andachten.jpg 1200w", "/static/ab55e55da99b6a964f8e8081989b7cdd/d9c39/andachten.jpg 1800w", "/static/ab55e55da99b6a964f8e8081989b7cdd/df51d/andachten.jpg 2400w", "/static/ab55e55da99b6a964f8e8081989b7cdd/1f748/andachten.jpg 3275w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`Das Gebet und das Lesen in Heiligen Schriften gehören zum Alltag vieler religiöser Menschen. Im persönlichen Gespräch mit Gott wird Dank ausgedrückt oder um Beistand und Führung gebeten.`}</p>
    <p>{`Bahá’í beten gemeinsam mit ihren Freunden, Bekannten und Nachbarn – unabhängig von ihrem religiösen Hintergrund – in Gemeindezentren oder privaten Wohnstätten. Bei diesen `}<a parentName="p" {...{
        "href": "https://www.bahai.de/gemeinsames-handeln/andacht-und-dienst/andachtsversammlungen/"
      }}>{`Andachtsversammlungen`}</a>{` werden Texte aus den Heiligen Schriften der Bahá’í-Religion und anderer Religionen gelesen und rezitiert, oft gehört auch Musik zur Andacht.`}</p>
    <p>{`Das gemeinsame Beten und Zusammensein bei einer Andacht verbindet die Menschen auf eine geistige Art und Weise. Die aus den heiligen Texten gewonnene Inspiration kann im Handeln ihren Ausdruck finden – sei es als Dienst am Nächsten oder als gemeinschaftliches Handeln für das Wohl der Gesellschaft.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      